import fr from '../lang/fr.json'
import de from '../lang/de.json'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    fr,
    de,
  },
}))
